<template>
  <div class="child-select">
    <el-checkbox-group v-model="selectChild" :size="controlSize" @change="changeChild">
      <div class="child-item" v-for="child in layerInfo.child" :key="child.id">
        <el-checkbox :label="child.id">{{ child.name }}</el-checkbox>
      </div>
    </el-checkbox-group>
    <div class="btn-group">
      <el-button type="danger" :size="controlSize" @click="save">确定</el-button>
      <el-button type="info" :size="controlSize" @click="$emit('close')">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layerInfo: Object
  },

  data() {
    return {
      controlSize: "mini",
      selectChild: []
    }
  },

  mounted() {
    this.selectChild = this.layerInfo.processchildId ? [this.layerInfo.processchildId] : []
  },

  methods: {
    changeChild(arr) {
      this.selectChild = arr.length ? [arr[arr.length - 1]] : []
    },

    save() {
      if (this.selectChild?.[0] === this.layerInfo.processchildId || (!this.selectChild.length && !this.layerInfo.processchildId)) {
        this.$emit("close")
        return false;
      }
      this.$emit("handle", {...this.layerInfo, processchildId: this.selectChild?.[0] || null})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "child-select";
</style>
